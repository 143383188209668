'use strict';

import L from 'leaflet';
import 'leaflet-routing-machine';

//closest-location.min
function Deg2Rad(a) {
    return a * Math.PI / 180
}

function PythagorasEquirectangular(a, e, n, r) {
    a = Deg2Rad(a), n = Deg2Rad(n), e = Deg2Rad(e), r = Deg2Rad(r)
    var t = 6371, i = (r - e) * Math.cos((a + n) / 2), o = n - a, d = Math.sqrt(i * i + o * o) * t
    return d
}

function NearestCity(a, e, n) {
    var r, t = 99999
    for (let index = 0; index < n.length; ++index) {
        var i = PythagorasEquirectangular(a, e, n[index][1], n[index][2])
        t > i && (r = index, t = i)
    }
    var o = n[r]
    return ("" + o[0]), o
}

window.Initialize = Initialize;
window.markerClickHandle = markerClickHandle;

// Variável do mapa, a localização inicial predefinida é a Praça 8 de Maio, Coimbra

var map;
var markerLayerGroup;
//variavel para escolha da Rota
const waypointsMap = new Map();
const waypointsIndexMap = new Map();
const waypointNameMap = new Map();

const markerLayerGroupMap = new Map();

const waypointColourMap = new Map();

var currentRouteIndex;
var currentRouteName;

var waypointSelected = false;

var new_current_position_marker;

var nomeRota;


//Waypoints hardcoded, podem estar por extenso no array caso não seja preciso dar call em mais sítio nenhum.
//É preferivel guardar assim se for para criar popups com info, morada, etc, de cada um dos sítios
//Waypoints Itinerário - Aqueduto da Água da Prata

var aquedutoAguaPrata =
    [["Arcaria Monumental", 38.57638889, -7.91333333]
        , ["Rua do Muro", 38.57527778, -7.91194444]
        , ["Fonte do Largo do Chão das Covas", 38.57472222, -7.91138889]
        , ["Caixa de Água da Rua Nova", 38.57166667, -7.90944444]
        , ["Fonte da Praça do Giraldo", 38.57472222, -7.90944444]
        , ["Fonte do Rossio de S. Brás", 38.56638889, -7.90722222]
        , ["Fonte das Portas de Moura", 38.57027778, -7.90527778]
        , ["Fonte da Porta Nova", 38.57583333, -7.90972222]];

    var descobertaEsgrafito =
    [["Largo Luís de Camões", 38.5730064630141, -7.91075941782426]
        , ["Rua Menino Jesus", 38.573474289571024, -7.908861473641746]
        , ["Rua de Mendo Estevens", 38.571461178106986, -7.903530587132301]
        , ["Rua do Cano", 38.574987022087235, -7.911249373641702]
        , ["Rua do Fradique ", 38.56931235918474, -7.906704231314883]
        , ["Rua do Cicioso", 38.56931235918474, -7.906704231314883]
        , ["Rua da Corredura", 38.57426298736086, -7.909773915968717]
        , ["Rua dos Três Senhores", 38.56947918204095, -7.906403444805405]
        , ["Rua Gabriel Victor do Monte Pereira, Évora", 38.57237247629812, -7.911548587132256]
        , ["Rua Vasco da Gama", 38.57245681500954, -7.90826426015133]
        , ["Rua 5 de Outubro", 38.575123321753075, -7.9099340024782006]
        , ["Rua de Aviz", 38.575123321753075, -7.9099340024782006]];

    var otherPoints =
    [["Colégio Espírito Santo", 38.57304598699217, -7.9041712871322245]
        , ["Cemitério Nossa Senhora dos remédios", 38.56893554461343, -7.916052991745286]
        , ["Museu Nacional Frei Manuel do Cenáculo", 38.57231166346843, -7.907137093077306]
        , ["convento-evora Nossa Senhora dos Remédios", 38.570066660796165, -7.915415488987853]
        , ["Biblioteca Pública de Évora", 38.57266457571818, -7.906939915968742]];

    var pedrasMonumentos =
    [["Igreja de São Tiago", 38.572078683225676, -7.909030935404356]
        , ["Colégio Mateus d'Aranda", 38.56904628290025, -7.912035144805412]
        , ["Alto de São Bento", 38.581056620165256, -7.937527802477974]
        , ["Igreja de São Brás", 38.56507814638233, -7.907290044805548]
        , ["Igreja da Graça", 38.56970240014056, -7.9070067625087]
        , ["Igreja do Carmo", 38.569918592834625, -7.905896687132362]
        , ["Igreja de Nossa Senhora da Pobreza", 38.568432730093576, -7.903250242949873]
        , ["Igreja de São Francisco", 38.56904715977307, -7.908797144805415]
        , ["Igreja do Espírito Santo", 38.57463307231548, -7.907771137259055]
        , ["Catedral de Évorao", 38.57194865246065, -7.90687827958689]
        , ["Igreja de São Mamede", 38.57463307231548, -7.907771137259055]
        , ["Templo Romano", 38.57281685542691, -7.907423148516262]];

    var abastecimento =
    [["Banhos públicos de Eborae Liberatis Iulia", 38.57253709991552, -7.909516644805283]
        , ["Aqueduto da Água de Prata", 38.56904628290025, -7.912035144805412]
        , ["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
        , ["Central Elevatória de Água", 38.57314678680571, -7.9087877025731945]];

    var figurasFalantes =
    [["Monumento aos Combatentes da Grande Guerra", 38.566261, -7.907600]
        , ["Museu Nacional Frei Manuel do Cenáculo", 38.57231166346843, -7.907137093077306]
        , ["Jardim Público de Évora", 38.56742029750168, -7.909270673641948]
        , ["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
        , ["Edifício dos Paços do Concelho", 38.57257903996437, -7.909570288987801]
        , ["Cemitério Nossa Senhora dos Remédios", 38.56894812726135, -7.916010076399273]
        , ["Jardim Diana", 38.573163645219466, -7.907902346660784]
        , ["Colégio Mateus d'Aranda", 38.56904628290025, -7.912035144805412]];

//Waypoints Itinerário - Central Elevatória de Águas
var centralElevatoriaAguas =
[["Câmara de manobras", 38.57444444, -7.91083333]
, ["CEA - Reservatórios de chegada", 38.57305556, -7.90888889]
, ["CEA - Estação Elevatória", 38.57305556, -7.90833333]
, ["Reservatório de serviço regulador de distribuição de água", 38.57250000, -7.90805556]];

//Waypoints Itinerário - Simetria
var simetria =
[["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
, ["Catedral de Évora", 38.57194865246065, -7.90687827958689]
, ["Igreja de São Francisco", 38.56904715977307, -7.908797144805415]
, ["Teatro Garcia de Resende", 38.571934165267834, -7.9093116313147895]
, ["Colégio Espírito Santo", 38.57304598699217, -7.9041712871322245]];

//Waypoints Itinerário - Arquitectura Ferro
var arquitecturaFerro =
[["Palácio Dom Manuel", 38.56804621329273, -7.90918816015145]
, ["Praça 1º de Maio", 38.56913695957379, -7.90902323131492]
, ["Jardim Público de Évora", 38.56742029750168, -7.909270673641948]
, ["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
, ["Paços do Concelho", 38.57257903996437, -7.909570288987801]];

//Waypoints Itinerário - Árvores e arbustos da cidade de Évora

var patrimonioBotanico = [
    ["Muralhas", 38.57055556, -7.91472222]
    , ["Largo da Graça", 38.56916667, -7.90750000]
    , ["Jardim Público", 38.56742029750168, -7.909270673641948]
    , ["Jardim Diana", 38.57305556, -7.90777778]
    , ["Largo dos Colegiais", 38.57333333, -7.90666667]
    , ["Praça do Sertório", 38.57222222, -7.90972222]
    , ["Largo da Misericórdia", 38.57027778, -7.90666667]
    , ["R. Duques do Cadaval", 38.57361111, -7.90694444]
    , ["Jardim Público de Évora", 38.56742029750168, -7.909270673641948]
    , ["Largo Marquês de Marialva", 38.57166667, -7.90722222]];

//Waypoints Itinerário - Jogos Matemáticos
var jogosMatematicos = [
    ["Templo Romano", 38.57250000, -7.90722222]
    , ["Travessa do Sertório", 38.57166667, -7.90944444]
    , ["Igreja de N. Sra. da Graça", 38.56916667, -7.90722222]
    , ["Porta de Alconchel", 38.57027778, -7.91444444]
    , ["convento-evora dos Remédios", 38.57000000, -7.91500000]];

//Waypoints Itinerário - Relógios de Sol
var relogiosDeSol = [
    ["Sé de Évora", 38.57166667, -7.90666667],
    ["Páteo de S. Miguel", 38.57250000, -7.90527778],
    ["Colégio do Espírito Santo", 38.57333333, -7.90444444],
    ["Igreja de S. Francisco", 38.56888889, -7.90888889],
    ["Cemitério do convento-evora dos Remédios", 38.56944444, -7.91555556]];

var allPoints = [
    ["Banhos públicos de Eborae Liberatis Iulia", 38.57253709991552, -7.909516644805283]
        , ["Aqueduto da Água de Prata", 38.56904628290025, -7.912035144805412]
        , ["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
        , ["Central Elevatória de Água", 38.57314678680571, -7.9087877025731945]
        , ["Igreja de São Tiago", 38.572078683225676, -7.909030935404356]
        , ["Colégio Mateus d'Aranda", 38.56904628290025, -7.912035144805412]
        , ["Alto de São Bento", 38.581056620165256, -7.937527802477974]
        , ["Igreja de São Brás", 38.56507814638233, -7.907290044805548]
        , ["Igreja da Graça", 38.56970240014056, -7.9070067625087]
        , ["Igreja do Carmo", 38.569918592834625, -7.905896687132362]
        , ["Igreja de Nossa Senhora da Pobreza", 38.568432730093576, -7.903250242949873]
        , ["Igreja de São Francisco", 38.56904715977307, -7.908797144805415]
        , ["Igreja do Espírito Santo", 38.57463307231548, -7.907771137259055]
        , ["Catedral de Évorao", 38.57194865246065, -7.90687827958689]
        , ["Igreja de São Mamede", 38.57463307231548, -7.907771137259055]
        , ["Templo Romano", 38.57281685542691, -7.907423148516262]
        , ["Colégio Espírito Santo", 38.57304598699217, -7.9041712871322245]
        , ["Cemitério Nossa Senhora dos remédios", 38.56893554461343, -7.916052991745286]
        , ["Museu Nacional Frei Manuel do Cenáculo", 38.57231166346843, -7.907137093077306]
        , ["convento-evora Nossa Senhora dos Remédios", 38.570066660796165, -7.915415488987853]
        , ["Biblioteca Pública de Évora", 38.57266457571818, -7.906939915968742]
        , ["Largo Luís de Camões", 38.5730064630141, -7.91075941782426]
        , ["Rua Menino Jesus", 38.573474289571024, -7.908861473641746]
        , ["Rua de Mendo Estevens", 38.571461178106986, -7.903530587132301]
        , ["Rua do Cano", 38.574987022087235, -7.911249373641702]
        , ["Rua do Fradique ", 38.56931235918474, -7.906704231314883]
        , ["Rua do Cicioso", 38.56931235918474, -7.906704231314883]
        , ["Rua da Corredura", 38.57426298736086, -7.909773915968717]
        , ["Rua dos Três Senhores", 38.56947918204095, -7.906403444805405]
        , ["Rua Gabriel Victor do Monte Pereira, Évora", 38.57237247629812, -7.911548587132256]
        , ["Rua Vasco da Gama", 38.57245681500954, -7.90826426015133]
        , ["Rua 5 de Outubro", 38.575123321753075, -7.9099340024782006]
        , ["Rua de Aviz", 38.575123321753075, -7.9099340024782006]
        , ["Monumento aos Combatentes da Grande Guerra", 38.566261, -7.907600]
        , ["Museu Nacional Frei Manuel do Cenáculo", 38.57231166346843, -7.907137093077306]
        , ["Jardim Público de Évora", 38.56742029750168, -7.909270673641948]
        , ["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
        , ["Edifício dos Paços do Concelho", 38.57257903996437, -7.909570288987801]
        , ["Cemitério Nossa Senhora dos Remédios", 38.56894812726135, -7.916010076399273]
        , ["Jardim Diana", 38.573163645219466, -7.907902346660784]
        , ["Colégio Mateus d'Aranda", 38.56904628290025, -7.912035144805412]
    ,["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
, ["Catedral de Évora", 38.57194865246065, -7.90687827958689]
, ["Igreja de São Francisco", 38.56904715977307, -7.908797144805415]
, ["Teatro Garcia de Resende", 38.571934165267834, -7.9093116313147895]
, ["Colégio Espírito Santo", 38.57304598699217, -7.9041712871322245]
, ["Palácio Dom Manuel", 38.56804621329273, -7.90918816015145]
, ["Praça 1º de Maio", 38.56913695957379, -7.90902323131492]
, ["Jardim Público de Évora", 38.56742029750168, -7.909270673641948]
, ["Praça do Giraldo", 38.57090600854237, -7.909434573641849]
, ["Paços do Concelho", 38.57257903996437, -7.909570288987801]
    , ["Arcaria Monumental", 38.57638889, -7.91333333]
    , ["Rua do Muro", 38.57527778, -7.91194444]
    , ["Fonte do Largo do Chão das Covas", 38.57472222, -7.91138889]
    , ["Caixa de Água da Rua Nova", 38.57166667, -7.90944444]
    , ["Fonte da Praça do Giraldo", 38.57472222, -7.90944444]
    , ["Fonte do Rossio de S. Brás", 38.56638889, -7.90722222]
    , ["Fonte das Portas de Moura", 38.57027778, -7.90527778]
    , ["Fonte da Porta Nova", 38.57583333, -7.90972222],
    ["Câmara de manobras", 38.57444444, -7.91083333]
    , ["CEA - Reservatórios de chegada", 38.57305556, -7.90888889]
    , ["CEA - Estação Elevatória", 38.57305556, -7.90833333]
    , ["Reservatório de serviço regulador de distribuição de água", 38.57250000, -7.90805556],
    ["Muralhas", 38.57055556, -7.91472222]
    , ["Largo da Graça", 38.56916667, -7.90750000]
    , ["Jardim Diana", 38.57305556, -7.90777778]
    , ["Largo dos Colegiais", 38.57333333, -7.90666667]
    , ["Praça do Sertório", 38.57222222, -7.90972222]
    , ["Largo da Misericórdia", 38.57027778, -7.90666667]
    , ["R. Duques do Cadaval", 38.57361111, -7.90694444]
    , ["Largo Marquês de Marialva", 38.57166667, -7.90722222],
    ["Templo Romano", 38.57250000, -7.90722222]
    , ["Travessa do Sertório", 38.57166667, -7.90944444]
    , ["Igreja de N. Sra. da Graça", 38.56916667, -7.90722222]
    , ["Porta de Alconchel", 38.57027778, -7.91444444]
    , ["convento-evora dos Remédios", 38.57000000, -7.91500000],
    ["Sé de Évora", 38.57166667, -7.90666667],
    ["Páteo de S. Miguel", 38.57250000, -7.90527778],
    ["Colégio do Espírito Santo", 38.57333333, -7.90444444],
    ["Igreja de S. Francisco", 38.56888889, -7.90888889],
    ["Cemitério do convento-evora dos Remédios", 38.56944444, -7.91555556]
]

var routeArray = [
    aquedutoAguaPrata,
    centralElevatoriaAguas,
    patrimonioBotanico,
    jogosMatematicos,
    relogiosDeSol,
    arquitecturaFerro,
    simetria,
    figurasFalantes,
    descobertaEsgrafito,
    pedrasMonumentos,
    otherPoints,
    abastecimento,
    allPoints
]

var current_position = new WeakMap();
var current_position_marker = new WeakMap();
var aToBRoute = new WeakMap();
var current_route_marker = new WeakMap();

var currentLat;
var currentLng;

// Layergroups dos markers
var comum_Icon = L.icon({
    iconUrl: new URL('../images/marker_orange.png', import.meta.url),
    iconSize: [25, 41],
});

var aquedutoAguaPrata_Layergroup = L.layerGroup();
aquedutoAguaPrata.forEach(ponto => {
    aquedutoAguaPrata_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).bindPopup(ponto[0]));
});

var figurasFalantes_Layergroup = L.layerGroup();
figurasFalantes.forEach(ponto => {
    figurasFalantes_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).bindPopup(ponto[0]));
});

var simetria_Layergroup = L.layerGroup();
simetria.forEach(ponto => {
    simetria_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).bindPopup(ponto[0]));
});

var arquitecturaFerro_Layergroup = L.layerGroup();
arquitecturaFerro.forEach(ponto => {
    arquitecturaFerro_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).bindPopup(ponto[0]));
});

var centralElevatoriaAguas_Layergroup = L.layerGroup();
centralElevatoriaAguas.forEach(ponto => {
    centralElevatoriaAguas_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).bindPopup(ponto[0]));
});

var patrimonioBotanico_Layergroup = L.layerGroup();
patrimonioBotanico.forEach(ponto => {
    patrimonioBotanico_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).on('click', markerClick).bindPopup(ponto[0]));
});

var jogosMatematicos_Layergroup = L.layerGroup();
jogosMatematicos.forEach(ponto => {
    jogosMatematicos_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).on('click', markerClick).bindPopup(ponto[0]));
});

var relogiosDeSol_Layergroup = L.layerGroup();
relogiosDeSol.forEach(ponto => {
    relogiosDeSol_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).on('click', markerClick).bindPopup(ponto[0]));
});

var abastecimento_Layergroup = L.layerGroup();
abastecimento.forEach(ponto => {
    abastecimento_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).on('click', markerClick).bindPopup(ponto[0]));
});

var otherPoints_Layergroup = L.layerGroup();
otherPoints.forEach(ponto => {
    otherPoints_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).on('click', markerClick).bindPopup(ponto[0]));
});

var pedrasMonumentos_Layergroup = L.layerGroup();
pedrasMonumentos.forEach(ponto => {
    pedrasMonumentos_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).on('click', markerClick).bindPopup(ponto[0]));
});

var descobertaEsgrafito_Layergroup = L.layerGroup();
descobertaEsgrafito.forEach(ponto => {
    descobertaEsgrafito_Layergroup.addLayer(L.marker([ponto[1], ponto[2]], {icon: comum_Icon}).on('click', markerClick).on('click', markerClick).bindPopup(ponto[0]));
});

var allPoints_Layergroup = L.layerGroup();
allPoints_Layergroup.addLayer(aquedutoAguaPrata_Layergroup);
allPoints_Layergroup.addLayer(centralElevatoriaAguas_Layergroup);
allPoints_Layergroup.addLayer(patrimonioBotanico_Layergroup);
allPoints_Layergroup.addLayer(jogosMatematicos_Layergroup);
allPoints_Layergroup.addLayer(relogiosDeSol_Layergroup);
allPoints_Layergroup.addLayer(arquitecturaFerro_Layergroup);
allPoints_Layergroup.addLayer(simetria_Layergroup);
allPoints_Layergroup.addLayer(figurasFalantes_Layergroup);
allPoints_Layergroup.addLayer(descobertaEsgrafito_Layergroup);
allPoints_Layergroup.addLayer(otherPoints_Layergroup);
allPoints_Layergroup.addLayer(pedrasMonumentos_Layergroup);
allPoints_Layergroup.addLayer(abastecimento_Layergroup);

function markerClick(e) {
    waypointSelected = true;
    //Check if route already exists, remove if true.
    if (aToBRoute.get(map)) {
        map.removeControl(aToBRoute.get(map));
    }

    //Create waypoints for current position and destination marker
    var route_waypoints = [current_position.get(map), e.target._latlng];

    //Add route to map
    let rota = getRoute(route_waypoints);
    rota.addTo(map);
    aToBRoute.set(map, rota);
    current_route_marker.set(map, e.target);
}

function getRoute(route_waypoints) {
    const TOKEN = 'pk.eyJ1IjoicmVmZXJlbmNpYXBpY3RvcmljYSIsImEiOiJja3d3OXhodncwMW9zMm91cmxobWE4b2ZrIn0.UU_6Mzyj2Z1s2tEgpZVu3g';

    return L.Routing.control({
        //Para correr
        // serviceUrl: 'https://evoracomcienciaosrm.duckdns.org/route/v1',
        router: L.Routing.mapbox(TOKEN),
        waypoints: route_waypoints,
        lineOptions: {
            styles: [{color: '#1d1d1d', opacity: 1, weight: 2.5}]
        },
        createMarker: function (i, start, n) {


            var myIcon = L.icon({
                iconUrl: new URL('../images/marker_road.png', import.meta.url),
                iconSize: [25, 41]
            });

            return L.marker(start.latLng, {
                icon: myIcon
            })
        },
        routeWhileDragging: false,
        draggableWaypoints: false,
        waypointMode: 'snap',
        addWaypoints: false,
        show: false,
        //scrollWheelZoom: false,
    });
}

function markerClickHandle(lat, lng) {
    map.panTo(new L.LatLng(lat, lng));

    waypointSelected = true;
    //Check if route already exists, remove if true.
    if (aToBRoute.get(map)) {
        map.removeControl(aToBRoute.get(map));
    }

    //Create waypoints for current position and destination marker
    var route_waypoints = [current_position.get(map), [lat, lng]];

    //Add route to map
    let rota = getRoute(route_waypoints);
    rota.addTo(map);

    aToBRoute.set(map, rota);
    // current_route_marker.set(map, e.target);

}

function Initialize(nomeBotao, routeName) {

    nomeRota = routeName;

    document.getElementById(nomeBotao).addEventListener("click", function () {
        SetWaypoints(map, nomeRota);
    });

    //Set map values
    waypointNameMap.set('aquedutoAguaPrata', 'Aqueduto da Água da Prata');
    waypointNameMap.set('centralElevatoriaAguas', 'Central Elevatória das Águas');
    waypointNameMap.set('patrimonioBotanico', 'Património Botânico');
    waypointNameMap.set('jogosMatematicos', 'Jogos Matemáticos');
    waypointNameMap.set('arquitecturaFerro', 'Arquitectura do Ferro');
    waypointNameMap.set('relogiosDeSol', 'Relógio de Sol');
    waypointNameMap.set('simetria', 'Simetria');
    waypointNameMap.set('figurasFalantes', 'Figuras Falantes');
    waypointNameMap.set('allPoints', 'Percursos');
    waypointNameMap.set('descobertaEsgrafito', 'Descoberta do Esgrafito');
    waypointNameMap.set('pedrasMonumentos', 'Pedras dos Monumentos');
    waypointNameMap.set('otherPoints', 'Conhecimento');
    waypointNameMap.set('abastecimento', 'Abastecimento de Águas');

    waypointsMap.set('aquedutoAguaPrata', aquedutoAguaPrata);
    waypointsMap.set('centralElevatoriaAguas', centralElevatoriaAguas);
    waypointsMap.set('patrimonioBotanico', patrimonioBotanico);
    waypointsMap.set('jogosMatematicos', jogosMatematicos);
    waypointsMap.set('arquitecturaFerro', arquitecturaFerro);
    waypointsMap.set('relogiosDeSol', relogiosDeSol);
    waypointsMap.set('simetria', simetria);
    waypointsMap.set('figurasFalantes', figurasFalantes);
    waypointsMap.set('descobertaEsgrafito', descobertaEsgrafito);
    waypointsMap.set('pedrasMonumentos', pedrasMonumentos);
    waypointsMap.set('otherPoints', otherPoints);
    waypointsMap.set('abastecimento', abastecimento);
    waypointsMap.set('allPoints', allPoints);

    waypointsIndexMap.set(1, aquedutoAguaPrata);
    waypointsIndexMap.set(2, centralElevatoriaAguas);
    waypointsIndexMap.set(3, patrimonioBotanico);
    waypointsIndexMap.set(4, jogosMatematicos);
    waypointsIndexMap.set(5, arquitecturaFerro);
    waypointsIndexMap.set(6, relogiosDeSol);
    waypointsIndexMap.set(7, simetria);
    waypointsIndexMap.set(8, figurasFalantes);
    waypointsIndexMap.set(9, descobertaEsgrafito);
    waypointsIndexMap.set(10, pedrasMonumentos);
    waypointsIndexMap.set(11, otherPoints);
    waypointsIndexMap.set(12, abastecimento);
    

    // Se isto funcionar, tentar apagar tudo em cima
    markerLayerGroupMap.set('aquedutoAguaPrata', aquedutoAguaPrata_Layergroup);
    markerLayerGroupMap.set('centralElevatoriaAguas', centralElevatoriaAguas_Layergroup);
    markerLayerGroupMap.set('patrimonioBotanico', patrimonioBotanico_Layergroup);
    markerLayerGroupMap.set('jogosMatematicos', jogosMatematicos_Layergroup);
    markerLayerGroupMap.set('arquitecturaFerro', arquitecturaFerro_Layergroup);
    markerLayerGroupMap.set('relogiosDeSol', relogiosDeSol_Layergroup);
    markerLayerGroupMap.set('simetria', simetria_Layergroup);
    markerLayerGroupMap.set('figurasFalantes', figurasFalantes_Layergroup);
    markerLayerGroupMap.set('abastecimento', abastecimento_Layergroup);
    markerLayerGroupMap.set('descobertaEsgrafito', descobertaEsgrafito_Layergroup);
    markerLayerGroupMap.set('pedrasMonumentos', pedrasMonumentos_Layergroup);
    markerLayerGroupMap.set('allPoints', allPoints_Layergroup);


    map = L.map('mapaCoimbra').setView([38.57638889, -7.91333333], 20);

    markerLayerGroup = new L.FeatureGroup().addTo(map);
    SetWaypoints(map, nomeRota);


    // Map Controls
    var visitasControls = {
        "Aqueduto Água Prata": aquedutoAguaPrata_Layergroup,
        "Central Elevatória Águas": centralElevatoriaAguas_Layergroup,
        "Árvores Arbustos": patrimonioBotanico_Layergroup,
        "Jogos Matemáticos": jogosMatematicos_Layergroup,
        "Relógio de Sol": relogiosDeSol_Layergroup,
        "Arquitectura do Ferro": arquitecturaFerro_Layergroup,
        "Simetria": simetria_Layergroup,
        "Figuras Falantes": figurasFalantes_Layergroup,
        "Descoberta do Esgrafito": descobertaEsgrafito_Layergroup,
        "Pedras dos Monumentos": pedrasMonumentos_Layergroup,
        "Conhecimento": otherPoints_Layergroup,
        "Abastecimento de Águas": abastecimento_Layergroup
    }

    if (nomeRota === "allPoints") {
        var layerControl = L.control.layers(null, visitasControls).addTo(map);
    }

    map.on('locationfound', onLocationFound);

    map.on('zoomend', function () {
        if (new_current_position_marker) {

            const locationIcon = L.icon({
                iconUrl: new URL('../images/pulse.gif', import.meta.url),
                iconSize: [62, 62]
            });

            new_current_position_marker.setIcon(locationIcon);
            current_position_marker.set(map, new_current_position_marker);
        }
    });
}

//Rota
function SetWaypoints(map, nomeRota) {

    currentRouteIndex = routeArray.indexOf(waypointsMap.get(nomeRota));

    document.getElementById("routename_map").innerHTML = waypointNameMap.get(nomeRota);

    if (markerLayerGroup.getLayers().length > 0) {

        markerLayerGroup.clearLayers();
    }

    // Se isto funcionar apagar lixo

    markerLayerGroupMap.get(nomeRota).addTo(map);
    //markerLayerGroupMap.get(nomeRota).addTo(map);

    L.tileLayer('https://{s}.tile.jawg.io/jawg-light/{z}/{x}/{y}{r}.png?access-token={accessToken}', {
        attribution: '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank"><b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        minZoom: 0,
        maxZoom: 32,
        subdomains: 'abcd',
        accessToken: 'hnrS5tsjLkSkiUhbYca3JcmmJfYZO62RQkCSuhEmDgZo5Ez4HkKjiV2Eah3ITrnO'
        //mapbox!! accessToken: 'pk.eyJ1IjoicmVmZXJlbmNpYXBpY3RvcmljYSIsImEiOiJja3d3OWlpcmowMWw4Mm9wOHpiMWVyZnUwIn0.jRMMBdgiAj3LxOa7yOLjsw'
    }).addTo(map);


    //Only update closest point if we have user location
    if (currentLat != undefined && currentLng != undefined) {
        RouteToClosestPoint(currentLat, currentLng, waypointsMap.get(nomeRota));
    }

    map.locate({
        watch: false,
        setView: true,
        maxZoom: 18,
        enableHighAccuracy: true,
        maximumAge: 60000, // 1 sec
    });
}

function onLocationFound(e) {
    //Array with current lat and lng
    current_position.set(map, [e.latlng.lat, e.latlng.lng]);
    currentLat = e.latlng.lat;
    currentLng = e.latlng.lng;

    var locationIcon = L.icon({
        iconUrl: new URL('../images/pulse.gif', import.meta.url),
        iconSize: [62, 62]
    });


    if (new_current_position_marker) {
        new_current_position_marker.setLatLng([current_position.get(map)[0], current_position.get(map)[1]])
    } else {
        new_current_position_marker =
            L.marker([currentLat, currentLng], {icon: locationIcon})
                .bindPopup("Estou aqui !")
                .addTo(map);
    }

    new_current_position_marker.setIcon(locationIcon);

    var target = e.target;

    current_position_marker.set(map, new_current_position_marker);

    if (current_route_marker.get(map)) {
        current_route_marker.get(map).fireEvent('click');
    }

    //STEFFAN - este código busca o ponto com lat lng mais próximo

    if (!waypointSelected) {
        RouteToClosestPoint(currentLat, currentLng, waypointsMap.get(nomeRota));
    }
}

function RouteToClosestPoint(currentLat, currentLng, nomeRota) {
    var nearestCity = NearestCity(currentLat, currentLng, nomeRota);
    var latlng = [nearestCity[1], nearestCity[2]];
    MakeRouteToLatLng(map, latlng);
}

function MakeRouteToLatLng(map, latlng) {

    //Check if route already exists, remove if true.
    if (aToBRoute.get(map)) {
        map.removeControl(aToBRoute.get(map));
    }

    //Create waypoints for current position and destination marker
    var route_waypoints = [current_position.get(map), latlng];

    //Add route to map
    let rota = getRoute(route_waypoints);
    rota.addTo(map);

    aToBRoute.set(map, rota);
    //current_route_marker.set(map, e.target);
}